.card{
    padding: 10px;
  }
  .card img{
    height: 270px;
    max-width: 270px;
  }
  
  .card h6{
    text-align: center;
    font-size: 1rem;
  }