.brands {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin: 0 auto;
    max-width: 100%;
  }
  
  .brands h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }

  .brands_images {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
    border-top: 1px solid rgb(232, 230, 230);
    border-bottom: 1px solid rgb(232, 230, 230);
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  .brands_images img {
    margin: 1rem;
    max-width: 46%;
    height: auto;
    transition: opacity 0.3s ease;
  }
  
  .brands_images img:hover {
    opacity: .5;
  }
  
  @media  (max-width: 768px){
    .brands_images img {
        max-width: 70%;
      }
  }