.mainContent {
    flex: 1;
    padding: 20px;
    overflow-x: hidden;
    max-width: 90%;
  }
  
.product_cards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

