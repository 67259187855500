/* src/components/Carousel.module.css */

.carousel {
    position: relative;
    width: 100%;
    /* Adjust as needed */
    height: 60vh;
    /* Adjust as needed */
    overflow: hidden;
    margin: auto;
    border: 1px solid #ccc;
}

.carouselImage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s ease;
    /* Smooth fade-in-out transition */
}

.carouselImage.active {
    opacity: 1;
}

.navButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 24px;
    width: 40px;
    height: 40px;
    display: none;
    transition: background-color 0.3s ease;
}

.navButton:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.navButton.prev {
    left: 10px;
}

.navButton.next {
    right: 10px;
}

.carousel:hover .navButton {
    display: block;
}